import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import Interview_Spring from '../component/Spring/Interview_Spring';


const JeongSeonYeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "광수❤️선영 결혼합니다.";
    const openDesc = "25년 1월 25일 토요일 오전 11시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/13/thumb.jpg";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#5D72BB",
        pointColor: "#5D72BB",
        btnBgColor: "#A2AFDF",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#F3F4F8",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김광수",
            bank: "토스뱅크",
            accountNumber : "1000-7607-6602",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej736WV22"
        },
        {
            role: "신랑 부",
            name: "김 성",
            bank: "신한은행",
            accountNumber : "110-049-533960",
            isKakao : false
        },
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "정선영",
            bank: "토스뱅크",
            accountNumber : "1000-7304-2423",
            isKakao :true,
            kakaoUrl: "https://qr.kakaopay.com/Fd17Mg96a"
        },
        {
            role: "신부 부",
            name: "정종국",
            bank: "카카오뱅크",
            accountNumber : "3333-13-3297503",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "최윤정",
            bank: "기업은행",
            accountNumber : "010-8761-6463",
            isKakao : false
        }
    ]
    

    const msg1 = "하나님이 맺어주신\n잇쉬와 잇샤처럼\n하나님 안에서\n한 가정을 이루고자 합니다.";
    const msg2 = "부디 귀한 시간 내주시어 기도해주시고 \n함께 축복해주시면 감사하겠습니다.";

    const lng = 126.828818362901;
    const lat = 37.308201295112;

    const placeTelNum = "031-501-5900";

    const forParking = "- 제1주차장\nAW컨벤션 지상, 지하 1층, 2층\n\n- 제2주차장\n양지주차타워 (AW컨벤션주차타워)\n\n-제3주차장\nAW컨벤션 정문 맞은편 공영주차장\n\n- 제4주차장\nMK주차타워"
    const forCar = "- 서울방향\n안산JC - 안산IC - 법원검찰청 - AW컨벤션 \n\n- 수원방향\n수인산업도로 - 법원검찰청 - AW컨벤션\n\n- 인천방향\n서안산IC - 법원검찰청 - AW컨벤션\n\n- 영동고속도로\n안산IC - 법원검찰청 - AW컨벤션\n\n- 서해안고속도로\n매송IC - 해안로(호수공원) - 시청방향 - AW컨벤션"
    const forBus = "- 안산 문화숲의 광장 하차 88, \n- 동남레이크빌 하차 99-1, 3100 \n - 대림호수공원아파트 하차 77, 98, 3";
    const forSubway = "4호선 고잔역 2번 출구\n셔틀버스는 예식 2시간 후까지 운행합니다.\n(셔틀버스 5분~7분 간격 수시운행 / 도보 15~20분)";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다.";

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "잊지 못하지",
            brideAnswer: "너무 기억나"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "심장터져",
            brideAnswer: "기도하자"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "많이 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "화이팅",
            brideAnswer: "잘하자"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "고마워",
            brideAnswer: "잘부탁해"
        }
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();

        // 스크롤 비활성화 (모바일 포함)
        const disableScroll = () => {
            document.body.style.overflow = 'hidden'; // 일반 스크롤 비활성화
            document.body.style.position = 'fixed'; // 모바일에서 터치 스크롤 비활성화
            document.body.style.width = '100%';
        };

        const enableScroll = () => {
            document.body.style.overflow = ''; // 스크롤 복구
            document.body.style.position = ''; // 모바일 복구
            document.body.style.width = '';
        };

        disableScroll();

        const timer = setTimeout(() => {
            enableScroll();
        }, 3000);

        return () => {
            clearTimeout(timer);
            enableScroll();
        };
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#5D72BB"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"소중한 날, 소중한 분들을 초대합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <Gallery_Spring color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forParking={forParking} forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default JeongSeonYeong;
