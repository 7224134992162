import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';

const LeeSongI = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김성준 ♥ 이송이 결혼합니다.";
    const openDesc = "파라다이스웨딩 2월 15일 오후 2시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/25/thumb.jpg";

    const placeTelNum = "053-552-0500";

    const forSubway = " * 동대구역에서 지하철 1호선 탑승 \n- 서부정류장 1번 출구 건너 버스 환승 / 택시(5분 소요)\n - 환승버스 \n· 564 (남대구IC 정류장 하차), \n· 641 (본리네거리2하차)" 
    + "\n\n* 서대구역에서 버스 이용 시 \n- 환승버스 급행8 (서대구역 남측 1) 승차 \n-> (학산공원) 하차 / 택시 (10분 소요)\n - 지하철 2호선 탑승 \n-> 두류역 하차 후 156버스 승차 남대구IC에서 하차";

    const forCar = "고속도로 이용시\n* 서대구TG에서 요금지불 후 \n- 남대구 IC진입 후 본리네거리 방면으로 진입 \n- 1km 전방 우측 파라다이스웨딩";
    const forParking = "    * 파라다이스 웨딩 지하주차장 이용가능\n(1시간 30분 무료)";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#000",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김성준",
            bank: "우리은행",
            accountNumber : "1002-263-225712",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "권숙자",
            bank: "농협은행",
            accountNumber : "815077-52-066786",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이송이",
            bank: "농협은행",
            accountNumber : "352-0238-9779-33",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej9HDbimg"
        },
        {
            role: "신부 부",
            name: "이영국",
            bank: "아이엠뱅크\n(대구은행)",
            accountNumber : "010-4509-9661",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "권점례",
            bank: "아이엠뱅크\n(대구은행)",
            accountNumber : "027-08-204969",
            isKakao : false
        }
    ]


    const msg1 = "함박눈 고운 겨울날 , \n저희 두 사람이 \n서로의 마음에 깊은 사랑의 언약을 하려 합니다.";
    const msg2 = "기쁨이든 슬픔이든 \n평생을 함께하며 나눌 소중한 사랑 \n축복과 격려주시면 더 없는 기쁨이 되겠습니다.";

    const lng = 128.533210876944;
    const lat = 35.8368668508677;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "완전 귀엽다",
            brideAnswer: "최고 잘생김"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "함 살아보자",
            brideAnswer: "마 그래보자"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "내가 잘할게",
            brideAnswer: "나도 잘할게"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "반품은없다",
            brideAnswer: "환불도없다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "잘 살게요♥",
            brideAnswer: " 오래오래♥"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
            console.log('stope');
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // // 성을 제외하고 이름만 추출 ex.Gayeong
    // const getFirstNameEn = (fullName) => {
    //     const nameParts = fullName.split(' '); 
    //     const firstName = nameParts.slice(1).join(''); 
    //     return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    // };

    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join('').toLowerCase(); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName; // 결과 문자열 반환
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#ffffff"} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} top={"26%"} bottom={"25%"} bottom2={"17%"} dateBottom={"2%"} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall weddingData={weddingData} isFormatWeddingTime={true}/>
                    <Letter title={"우리 결혼 합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forSubway={forSubway}forCar={forCar} forParking={forParking} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default LeeSongI;