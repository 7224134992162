import { useParams } from 'react-router-dom';
import Spring from './page/Spring';
import GuestBookCake from './page/GuestBookCake';
import Admin from './page/Admin';
import Summer from './page/Summer';
import Fall from './page/Fall';
import Winter from './page/Winter';
import SunkJin from './page/SunkJin';
import Loading from './component/Loading';
import JeongYunJi from './page/JeongYunJi';
import UmMinYoung from './page/UmMinYoung';
import KimKaYoung from './page/KimKaYoung';
import KimSol from './page/KimSol';
import ShinSoBin from './page/ShinSoBin';
import ParkSeoYoung from './page/ParkSeoYoung';
import KimMinJi from './page/KimMinJi';
import JeongSeonYeong from './page/JeongSeonYeong';
import ShinHyeYeon from './page/ShinHyeYeon';
import GilTaeEun from './page/GilTaeEun';
import YoonYuJin from './page/YoonYuJin';
import ParkNaYoung from './page/ParkNaYoung';
import JangSooYeon from './page/JangSooYeon';
import SongJooHee from './page/SongJooHee';
import YooSeulKi from './page/YooSeulKi';
import LeeJiEun from './page/LeeJiEun';
import KimYoonJi from './page/KimYoonJi';
import KoEunYoung from './page/KoEunYoung';
import ParkHoJeong from './page/ParkHoJeong';
import LeeSongI from './page/LeeSongI';
import LeeGaHyang from './page/LeeGaHyang';
import AnHyeJin from './page/AnHyeJin';
import KimJeongA from './page/KimJeongA';

// 각 mainPage에 해당하는 컴포넌트를 설정
const pageComponents = {
    'sample_spring': Spring,
    'sample_summer': Summer,
    'sample_fall' : Fall,
    'sample_winter' : Winter,
    'soohoonlove': SunkJin,
    'wedding250419': JeongYunJi,
    '20250216mysion': UmMinYoung,
    'kyjh0309': KimKaYoung,
    'jinwooandsol' : KimSol,
    'jwsb250308': ShinSoBin,
    'iusy250301' : ParkSeoYoung,
    'k250329' : KimMinJi,
    'sgys250125' : JeongSeonYeong,
    'SwHy250222' : ShinHyeYeon,
    'ygte0301' : GilTaeEun,
    '250419' : YoonYuJin,
    'S2nayoungcheolS2' : ParkNaYoung,
    'S250329' : JangSooYeon,
    'hoonhee' : SongJooHee,
    '250405wedding' : YooSeulKi,
    'pjklje0309' : LeeJiEun,
    'WoneyYoonG' : KimYoonJi,
    '250222' : KoEunYoung,
    'hjxjk0405' : ParkHoJeong,
    'potato' : LeeSongI,
    '2hojin2gahyang' : LeeGaHyang,
    'hanjin0215' : AnHyeJin,
    'omss2kja' : KimJeongA,
};

function DynamicPage() {
    const { mainPage, subPage } = useParams();
    const Component = pageComponents[mainPage];  // mainPage에 해당하는 컴포넌트를 선택

    if (!Component) return <Loading/>;

    // subPage가 'guest'일 때 GuestBookCake 컴포넌트 렌더링
    if (subPage === 'guest') return <GuestBookCake />;
    
    // subPage가 'admin'일 때 Admin 컴포넌트 렌더링
    if (subPage === 'admin') return <Admin />;
    
    // subPage가 없으면 기본 Component를 렌더링
    return <Component />;
}

export default DynamicPage;
