import React from 'react';
import "../../css/info_spring.css"
import MotionWrapper from '../MotionWrapper';


const Info_Spring = ({weddingData, conceptStyle, brideNameEn, groomNameEn, parents, parentsDesc, brideName, groomName}) => {
    const removeSpaces = (str) => {
        return typeof str === 'string' ? str.replace(/\s+/g, '') : ''; 
    };

    return (
        <div className='info_container' style={{color : `${conceptStyle.pointColor}`}}>
            <MotionWrapper>
                <div className='info_name_box'>
                    <p>{removeSpaces(groomNameEn)}</p>
                    <svg width="13" height="11"  xmlns="http://www.w3.org/2000/svg">
                        <path fill={`${conceptStyle.pointColor}`} d="M6.51614 10.5384C6.51614 10.5384 5.11979 8.83348 3.69685 8.19405C2.27391 7.55462 0.997613 6.08221 0.997613 6.08221C0.997613 6.08221 -1.05294 4.0284 0.725662 1.39341C2.50427 -1.24205 6.09202 0.327379 6.51614 2.26492C6.51614 2.26492 7.23326 -0.641389 10.4137 0.172337C13.5942 0.986063 12.9455 4.41551 12.9455 4.41551C12.9455 4.41551 12.4432 6.25627 10.33 7.49636C8.21655 8.73645 6.51614 10.5384 6.51614 10.5384Z"/>
                    </svg>
                    <p>{removeSpaces(brideNameEn)}</p>
                </div>
            </MotionWrapper>

            <MotionWrapper>
                <div className='info_date_box'>
                    <p>{weddingData.month}.{weddingData.date}</p>
                    <p>{weddingData.year}</p>
                </div>
            </MotionWrapper>
            
            <MotionWrapper>
                {parents && (
                    <div className='info_parents_box' style={{maxWidth: "370px", fontFamily: conceptStyle.fontFamilyKo, display: "flex", color: "#313131", textAlign : "center", fontSize: "13px", justifyContent: "center", margin: "5px auto"}}>
                        {parents.map((parent, index) => (
                            <div key={index} className='parent_info' style={{padding: "0 1em"}}>
                                {parent.groom && <p>{parent.groom}</p>}
                                {parent.bride && <p>{parent.bride}</p>}
                            </div>
                        ))}
                    </div>
                )}
                {parentsDesc && (
                    <div className='info_parents_box' style={{maxWidth: "370px", fontFamily: conceptStyle.fontFamilyKo, display: "flex", color: "#313131", textAlign : "center", fontSize: "13px", justifyContent: "center", margin: "15px auto", marginTop: "0px" }}>
                    {parentsDesc.map((info, index) => (
                        <div key={index} className='parent_info' style={{fontSize: "12px"}}>
                            {info.groom && <p style={{marginRight: "70px"}}>{info.groom}</p>}
                            {info.bride && <p>{info.bride}</p>}
                        </div>
                    ))}
                </div>
                )}
            </MotionWrapper>
            <MotionWrapper>
                <div className='info_detail_name_box'>
                    <span>
                        <p>신랑</p> <p>{groomName?groomName:weddingData.groomName}</p>
                    </span>
                    <span>
                        <p>신부</p> <p>{brideName?brideName:weddingData.brideName}</p>
                    </span>
                </div>
            </MotionWrapper>

            <MotionWrapper>
                <div className='info_detail_date_box'>
                    <p>{weddingData.year}.{weddingData.month}.{weddingData.date} {weddingData.dayOfWeek} {weddingData.time}</p>
                    <p style={{lineHeight : "18px"}}>{weddingData.place.replace(/\\n/g, '\n')}</p>
                </div>
            </MotionWrapper>

        </div>
    );
};

export default Info_Spring;