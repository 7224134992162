import React, { useState, useRef } from "react";

const AutoPlayAudio = () => {
  const [isPlaying, setIsPlaying] = useState(false); // 초기 상태는 재생 중이 아님
  const audioRef = useRef(null);

  const toggleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause(); // 재생 중이면 오디오 중지
    } else {
      audioRef.current.play(); // 중지 상태면 오디오 재생
    }
    setIsPlaying(!isPlaying); // 재생 상태 업데이트
  };

  return (
    <div>
      {/* <audio ref={audioRef} src="../music/Love_Letter.mp3" loop />
      <button onClick={toggleAudio}>
        {isPlaying ? "Pause Music" : "Play Music"}
      </button> */}
    </div>
  );
};

export default AutoPlayAudio;
