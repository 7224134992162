import React, { useState, useEffect } from 'react';
import ModalTemplate from '../ModalTemplate'; 
import axios from 'axios';
import '../../css/spring/message_spring.css'

function RSVPModal({isModalOpen, isSecondModal, tmpCloseModal, closeModal, goToSecondModal, conceptStyle, weddingData}) {
    const API_URL = process.env.REACT_APP_API_URL;

    const modalText = "모든 분들을 소중한 마음으로 모실 수 있도록 \n참석의사 전달 부탁드립니다.";
    const private_info = "청첩장 이용 종료시까지 개인정보는 저장되며, 이후 파기됩니다.";

    const [formData, setFormData] = useState({
        weddingPartyRole: '',  // 신랑/신부
        willAttend: '', // 참석/불참
        name: '', // 성함
        phoneNumber: '', // 연락처
        companionCount: '', // 본인 외 동반자 수
        willHaveMeal: '' // 식사 여부
    });

    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false); // 체크박스 상태 관리
    const [isFormComplete, setIsFormComplete] = useState(false); // 전달하기 버튼 활성화 여부

    // 폼 데이터 업데이트
    const handleButtonClick = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,  
            [name]: value 
        }));
    };

    const handleSubmit = () => {
        if (isFormComplete) {
            const requestData = {
                ...formData,
                invitationId: weddingData.id
            };
    
            axios
                .post(`${API_URL}/_api/v1/reply`, requestData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    localStorage.setItem('secondModalSubmitted', 'true');
                    closeModal();
                })
                .catch(error => {
                    console.error('요청 에러:', error.message);
                });
        }
    };
    

    useEffect(() => {
        const isAllFieldsFilled = Object.values(formData).every(value => value !== '');
        setIsFormComplete(isAllFieldsFilled && isPrivacyChecked);
    }, [formData, isPrivacyChecked]);

    useEffect(() => {
        if (isModalOpen || isSecondModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    return (
        <div>
        <ModalTemplate
            isOpen={isModalOpen && !isSecondModal}
            title="RSVP"
            description="참석 의사 전달"
            tmpCloseModal={tmpCloseModal}
            color={conceptStyle.titleColor}
            fontFamilyKo={conceptStyle.fontFamilyKo}
            >

            <p className='p1_score_13' style={{ paddingTop: "20px", fontFamily:conceptStyle.fontFamilyKo }}>{modalText}</p>
            <p className='p1_score_12' style={{ padding: "29px 0", fontFamily: conceptStyle.fontFamilyKo }}>
                {`${weddingData.year}년 ${weddingData.month}월 ${weddingData.date}일 ${weddingData.time}`}
                <br />
                {weddingData.place.replace(/\\n/g, '\n')}
            </p>
            <button className='spring_btn' onClick={goToSecondModal} style={{backgroundColor : conceptStyle.btnBgColor, color: conceptStyle.btnFontColor, fontFamily:conceptStyle.fontFamilyKo }}>전달하기</button>
        </ModalTemplate>

        {/* 두 번째 모달 */}
        <ModalTemplate
            isOpen={isModalOpen && isSecondModal}
            title="RSVP"
            description="참석 의사 전달"
            tmpCloseModal={tmpCloseModal}
            fontFamilyKo={conceptStyle.fontFamilyKo}
            color={conceptStyle.titleColor}
        >
            <div className='modal_item_wrap' style={{fontFamily: conceptStyle.fontFamilyKo}}>
                {/* 구분: 신랑/신부 버튼 */}
                <div>
                    <span style={{display: "flex", alignItems : "center", paddingBottom: "5px", fontSize:"9px"}}>
                        <p className="modal_item_title">구분</p> 
                        {formData.weddingPartyRole === '' && <p style={{color: "#FF8A83", paddingLeft: "2px"}}>*</p>}
                    </span>
                    <div className='modal_item_box'>
                        <button
                            className={`btn ${formData.weddingPartyRole === 'GROOM' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('weddingPartyRole', 'GROOM')}
                            style={{color: "#313131"}}
                        >
                            신랑
                        </button>
                        <button
                            className={`btn ${formData.weddingPartyRole === 'BRIDE' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('weddingPartyRole', 'BRIDE')}
                            style={{color: "#313131"}}
                        >
                            신부
                        </button>
                    </div>
                </div>

                {/* 참석 여부: 참석/불참 버튼 */}
                <div>
                    <span style={{display: "flex", alignItems : "center", paddingBottom: "5px", fontSize:"9px"}}>
                        <p className="modal_item_title">참석 여부</p> 
                        {formData.willAttend === '' && <p style={{color: "#FF8A83", paddingLeft: "2px"}}>*</p>}
                    </span>
                    <div className='modal_item_box'>
                        <button
                            className={`btn ${formData.willAttend === 'Y' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('willAttend', 'Y')}
                            style={{color: "#313131"}}
                        >
                            참석
                        </button>
                        <button
                            className={`btn ${formData.willAttend === 'N' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('willAttend', 'N')}
                            style={{color: "#313131"}}
                        >
                            불참
                        </button>
                    </div>
                </div>

                {/* 성함 입력 */}
                <div>
                    <span style={{display: "flex", alignItems : "center", paddingBottom: "5px", fontSize:"9px"}}>
                        <p className="modal_item_title">성함</p> 
                        {formData.name === '' && <p style={{color: "#FF8A83", paddingLeft: "2px"}}>*</p>}
                    </span>
                    <div className='modal_item_box'>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={(e) => handleButtonClick('name', e.target.value)}
                        required
                    />
                    </div>
                </div>

                {/* 연락처 입력 */}
                <div>
                    <span style={{display: "flex", alignItems : "center", paddingBottom: "5px", fontSize:"9px"}}>
                        <p className="modal_item_title">연락처</p> 
                        {formData.phoneNumber === '' && <p style={{color: "#FF8A83", paddingLeft: "2px"}}>*</p>}
                    </span>
                    <div className='modal_item_box'>
                    <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={(e) => handleButtonClick('phoneNumber', e.target.value)}
                        required
                    />
                    </div>
                </div>

                {/* 본인 외 동반인 수 입력 */}
                <div>
                    <span style={{display: "flex", alignItems : "center", paddingBottom: "5px", fontSize:"9px"}}>
                        <p className="modal_item_title">본인 외 동반인 수</p> 
                        {formData.companionCount === '' && <p style={{color: "#FF8A83", paddingLeft: "2px"}}>*</p>}
                    </span>
                    <div className='modal_item_box'>
                    <input
                        type="tel"
                        name="companionCount"
                        value={formData.companionCount}
                        onChange={(e) => handleButtonClick('companionCount', e.target.value)}
                        required
                    />
                    </div>
                </div>

                {/* 식사 여부 */}
                <div>
                    <span style={{display: "flex", alignItems : "center", paddingBottom: "5px", fontSize:"9px"}}>
                        <p className="modal_item_title">식사 여부</p> 
                        {formData.willHaveMeal === '' && <p style={{color: "#FF8A83", paddingLeft: "2px"}}>*</p>}
                    </span>
                    <div className='modal_item_box'>
                        <button
                            className={`btn ${formData.willHaveMeal === 'Y' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('willHaveMeal', 'Y')}
                            style={{color: "#313131"}}
                        >
                            식사
                        </button>
                        <button
                            className={`btn ${formData.willHaveMeal === 'N' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('willHaveMeal', 'N')}
                            style={{color: "#313131"}}
                        >
                            안함
                        </button>
                    </div>
                </div>

                <div style={{fontSize: "8px", color: "#313131"}}>
                    <span style={{display: "flex", justifyContent: "center"}}>
                        <input
                                type="checkbox"
                                checked={isPrivacyChecked}
                                onChange={() => setIsPrivacyChecked(!isPrivacyChecked)}
                                style={{ width: "8px", height: "8px", marginRight: "5px" }}
                            />
                        <p>개인정보 수집 및 이용 동의 (필수)</p>
                    </span>
                    <p>{private_info}</p>
                </div>
            </div>

            {/* 모든 항목이 입력되었을 때만 버튼 활성화 */}
            <button
                className="spring_btn"
                style={{ marginTop: "15px", marginBottom: "3px", width: "217px", backgroundColor : conceptStyle.btnBgColor, color: conceptStyle.btnFontColor, fontFamily: conceptStyle.fontFamilyKo }}
                onClick={handleSubmit}
                disabled={!isFormComplete}  
            >
                전달하기
            </button>
        </ModalTemplate>
        </div>
    );
}

export default RSVPModal;
