import React from 'react';

const GuestBookCard = ({ color, guestBook, index }) => {
    console.log("guestBook in GuestBookCard:", guestBook); // 여기에도 로그 추가
    return (
        <div style={{ width: "290px", height: "hug", backgroundColor: "#ffffff", borderRadius: "15px", boxShadow: "0 4px 4px rgba(0,0,0,0.3)", margin: "10px" }}>
            <div style={{ width: "260px", margin: "0 auto", padding: "15px 0", color: "#313131" }}>
                <span style={{ fontSize: "11px", textAlign: "left" }}>
                    <span style={{display: "flex"}}>
                        <p style={{color: color, marginRight: "1px"}}>{index}</p>
                        <p> 번째 체리</p>
                    </span>
                    
                    <p>{guestBook?.message}</p> 
                </span>
                <p style={{ textAlign: "right", fontSize: "12px" }}>
                    FROM. {guestBook?.name}
                </p>
            </div>
        </div>
    );
};

export default GuestBookCard;