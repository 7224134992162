import React, {useState} from 'react';
import AccountCard from './AccountCard';
import line from '../../css/line.png';
import moreBtn from "../../css/moreBtn.png"
import foldBtn from "../../css/foldBtn.png"

const AccountList = ({conceptStyle, groomAccountList, brideAccountList, account_explain, titleFontSize, fontSize}) => {
    const [showGroomAccounts, setShowGroomAccounts] = useState(false);
    const [showBrideAccounts, setShowBrideAccounts] = useState(false);

    const handleToggleGroomAccounts = () => {
        setShowGroomAccounts((prevState) => !prevState);
    };

    const handleToggleBrideAccounts = () => {
        setShowBrideAccounts((prevState) => !prevState);
    };

    return (
        <div className='basic_column_container' style={{fontFamily: conceptStyle.fontFamilyKo}}>
            <h3 className='spring_h3_title' style={{color: conceptStyle.titleColor, fontFamily: conceptStyle.fontFamilyEn}}>ACCOUNT NUMBER</h3>
            <p className='p1_score_14' style={{fontFamily: conceptStyle.fontFamilyKo, paddingBottom: "20px", fontSize: titleFontSize?titleFontSize:"14px"}}>마음 전하실 곳</p>
            <p className='p1_score_12' style={{fontFamily: conceptStyle.fontFamilyKo, fontSize:fontSize?fontSize:"13px"}}>{account_explain}</p>

            <div style={{ marginTop: "30px", marginBottom: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
                <div style={{ display:"flex", alignItems: "center", textAlign: "center", justifyContent: "center", cursor: "pointer" }} onClick={handleToggleGroomAccounts}>
                    <p style={{ marginRight: "10px", fontSize:fontSize?fontSize:"13px" }}>신랑측</p>
                        {groomAccountList.length > 0 && (
                            <img
                                src={showGroomAccounts ? foldBtn : moreBtn}
                                alt={showGroomAccounts ? "접기" : "더보기"}
                                style={{ width: "12px", height: "9px", cursor: "pointer"  }}
                            />
                        )}
                </div>
                {showGroomAccounts && (
                    <div>
                        {groomAccountList.map((groomAccount, index) => (
                            <AccountCard
                                key={index} // 고유 key 설정
                                role={groomAccount.role}
                                name={groomAccount.name}
                                bank={groomAccount.bank}
                                accountNumber={groomAccount.accountNumber}
                                isKakao={groomAccount.isKakao}
                                kakaoUrl={groomAccount.isKakao ? groomAccount.kakaoUrl : undefined}
                                titleFontSize={titleFontSize}
                                fontSize={fontSize}
                            />
                        ))}
                    </div>
                )}
            </div>


            <div>
                <div style={{ display:"flex", alignItems: "center", textAlign: "center", justifyContent: "center", cursor: "pointer" }} onClick={handleToggleBrideAccounts}>
                    <p style={{ marginRight: "10px", fontSize:fontSize?fontSize:"13px" }}>신부측</p>
                    {brideAccountList.length > 0 && (
                            <img
                                src={showBrideAccounts ? foldBtn : moreBtn}
                                alt={showBrideAccounts ? "접기" : "더보기"}
                                style={{ width: "12px", height: "9px"}}
                            />
                        )}
                </div>
                {showBrideAccounts && (
                    <div>
                        {brideAccountList.map((brideAccount, index) => (
                            <AccountCard
                                key={index} // 고유 key 설정
                                role={brideAccount.role}
                                name={brideAccount.name}
                                bank={brideAccount.bank}
                                accountNumber={brideAccount.accountNumber}
                                isKakao={brideAccount.isKakao}
                                kakaoUrl={brideAccount.isKakao ? brideAccount.kakaoUrl : undefined}
                                titleFontSize={titleFontSize}
                                fontSize={fontSize}
                            />
                        ))}
                    </div>
                )}
            </div>

            <img className='line_img' src={line} alt='Line Image'></img>
        </div>
    );
};

export default AccountList;