import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DynamicPage from './DynamicPage';
import { HelmetProvider } from 'react-helmet-async';

function App() {
    

    return (
    <div className="App">
        <HelmetProvider>
            <Router>
                <Routes>
                    <Route path="/:mainPage" element={<DynamicPage />} />
                    <Route path="/:mainPage/:subPage" element={<DynamicPage />} />
                </Routes>
            </Router>
        </HelmetProvider>
    </div>
    );
}

export default App;
