import React from 'react';
import "../../css/spring/account_spring.css";
import copyImg from '../../css/copy.png';
import kakaopayImg from '../../css/kakaopay.png';

const AccountCard = ({role, name, bank, accountNumber, isKakao, kakaoUrl, titleFontSize, fontSize}) => {
    const handleCopyClipBoard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            alert("계좌번호가 복사되었습니다🤍");
        } catch (err) {
            console.log(err);
        }
    };

    const handleKakaoPayClick = () => {
        if (kakaoUrl) {
            window.open(kakaoUrl, "_blank"); // 새 탭에서 kakaoUrl로 이동
        } else {
            alert("카카오페이 링크가 없습니다.");
        }
    };

    return (
        <div className='account_card'>
            <div className='account_name'>
                <p style={{fontSize : titleFontSize?titleFontSize:"13px"}}>{role} {name}</p>
            </div>
            <div className='account_box'>
                <span style={{fontSize : fontSize?fontSize:"12px"}}>
                    <p>{bank}</p> <p>{accountNumber}</p>
                </span>
                <span>
                    {isKakao && <img className='kakaopay_img' src={kakaopayImg} alt='Kakaopay Image' onClick={handleKakaoPayClick}/>}
                    <img className='copy_img' src={copyImg} alt='Copy Image' onClick={() => handleCopyClipBoard(accountNumber)}></img>
                </span>
            </div>
        </div>
    );
};

export default AccountCard;