import React, { useState, useEffect, useRef } from 'react';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import axios from 'axios';
import musicStopImg from '../css/music_stop.png';
import musicFile from "../asset/music/summer.mp3";
import musicPlayImg from "../css/music_start.png"; 
import Loading from '../component/Loading';
import AccountList from '../component/Account/AccountList';
import Location_Desc from '../component/Location/Location_Desc';
import Sharing from '../component/Sharing';
import { Helmet } from 'react-helmet-async';

const SunkJin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const msg1 = "함께 있을 때\n가장 나다운 모습이 되고\n함께 있을 때\n더 지혜로워지는 사람을 만나\n드디어 결혼 합니다";
    const msg2 = "지금처럼 서로에게 가장 친한 친구이자\n인생의 단짝이 되어 이쁘고 행복하게 \n잘 살겠습니다";
    const msg3 = "저희 두 사람이 첫 걸음을 내딛는\n뜻 깊은 자리에 소중한 시간내어\n함께 해주신다면\n더 없는 기쁨으로 간직 하겠습니다";
    const msg4 = "\n수훈 숙진 올림";

    const lng = 129.07550725617253;
    const lat = 35.17781048755416;

    const placeTelNum = "051-668-7979";
    const forParking = "국민연금관리공단 지하 2 ~4층 3시간 무료주차";
    const forSubway = "지하철 1호선 시청역 하차 2번 출구";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const openTitle = "최수훈❤️최숙진 저희 결혼 합니다";
    const openDesc = "1월12일 12시30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/6/S__50225156.jpg";

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false); // 재생 상태 관리
    const audioRef = useRef(new Audio(musicFile)); 

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:''
    });

    const conceptStyle = {
            concept: "SUMMER",
            titleColor: "#f00089",
            pointColor: "#f00089",
            btnBgColor: "#000000",
            fontColor: "#fff",
            btnFontColor: "#fff",
            chatBgColor: "#fff5f9",
            fontFamilyKo: "SCDream3",
            fontFamilyEn: "Edensor"
    }

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();

        // 스크롤 비활성화 (모바일 포함)
        const disableScroll = () => {
            document.body.style.overflow = 'hidden'; // 일반 스크롤 비활성화
            document.body.style.position = 'fixed'; // 모바일에서 터치 스크롤 비활성화
            document.body.style.width = '100%';
        };

        const enableScroll = () => {
            document.body.style.overflow = ''; 
            document.body.style.position = '';
            document.body.style.width = '';
        };

        disableScroll();

        const timer = setTimeout(() => {
            enableScroll();
        }, 3000);

        return () => {
            clearTimeout(timer);
            enableScroll();
        };
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');
                console.log(response, " : response")
                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; // 모든 폰트가 로드될 때까지 대기
            setFontsLoaded(true); // 폰트 로드 완료 시 상태 변경
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join('').toLowerCase(); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName; // 결과 문자열 반환
    };

    // 성을 제외하고 이름만 추출 ex.Ga Yeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const groomAccountList = [
        {
            role: "신랑",
            name: "최수훈",
            bank: "카카오뱅크",
            accountNumber : "3333 07 8972293",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "최기수",
            bank: "부산은행",
            accountNumber : "054 12 0428 62 1",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "이다경(검심)",
            bank: "국민은행",
            accountNumber : "116 21 0487 122",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "최숙진",
            bank: "카카오뱅크",
            accountNumber : "3333 29 2451648",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "박덕순",
            bank: "국민은행",
            accountNumber : "85500 01 062325",
            isKakao : false
        }
    ]

    const handleMusicToggle = () => {
        const audio = audioRef.current;
        if (isPlaying) {
          audio.pause(); // 재생 중이라면 멈춤
        } else {
          audio.play(); // 멈춤 상태라면 재생
        }
        setIsPlaying(!isPlaying); // 상태 업데이트
    };


    const parents = [
        {groom : "부 최기수 · 모 이다경"},
        {bride : "부 최원준 · 모 박덕순"}
    ]

    const parentsDesc = [
        {groom : "(이검심)"},
        {bride : ""}
    ]
    
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <> 
                <Helmet>
                    <title>최수훈❤️최숙진 저희 결혼 합니다</title>
                    <meta property="og:title" content="최수훈❤️최숙진 저희 결혼 합니다" />
                    <meta property="og:description" content="1월12일 12시30분" />
                    <meta property="og:image" content="https://moodsean.s3.ap-northeast-2.amazonaws.com/6/S__50225156.jpg" />
                    <meta property="og:url" content="https://moodsean.com/soohoonlove" />
                </Helmet>
                <img src={isPlaying ? musicPlayImg : musicStopImg} style={{width: "29px", position: "fixed", top: "1%", right: "3%", cursor: "pointer", zIndex:"1500"}}
                    onClick={handleMusicToggle} alt="Music Control"/>
                <Main_Summer color={conceptStyle.pointColor} weddingData={weddingData} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={conceptStyle.pointColor} dateColor={conceptStyle.pointColor} bottom={"38%"} bottom2={"28%"} dateBottom={"14%"}/>
                <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)} parents={parents} parentsDesc={parentsDesc}/>
                <Letter title={"저희 결혼 합니다 ♥︎"} msg1={msg1} msg2={msg2} msg3={msg3} msg4={msg4} conceptStyle={conceptStyle}/>
                <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                        <Gallery_Spring color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"}/>
                        <MotionWrapper initialY={70}>
                            <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain}/>
                            <MotionWrapper initialY={70}>
                                {/* <Message_Spring goToGuestBook={goToGuestBook} weddingDate = {weddingData.weddingDate} conceptStyle={conceptStyle}/> */}
                                <MotionWrapper initialY={50}>
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forParking={forParking} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer/>
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper> 
                </>    
            )}
        </div>
    );
};

export default SunkJin;