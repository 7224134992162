import React from 'react';
import { PropagateLoader } from 'react-spinners';

const Loading = ({ color, loading }) => {
    return (
        <div
            style={{
                position: "fixed", // Use `fixed` to ensure it covers the entire viewport
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                // backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent black background
                display: "flex", // Use flexbox for centering
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999, // Ensure it's on top of other elements
            }}
        >
            <PropagateLoader color={color} loading={loading} size={10} />
        </div>
    );
};

export default Loading;
