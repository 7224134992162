import React from 'react';
import '../../css/fall/main.css';

const Main_Fall = ({brideNameEn, groomNameEn, weddingData, conceptStyle, photoWidth}) => {

    return (
        <div className='fall_main_container' style={{color: conceptStyle.pointColor}}>
            <div className='main_text_box'> 
                <p style={{color: "#4E4E4E", fontSize: "15px", fontFamily: "Milchella", marginTop: "10px"}}>The wedding of</p>
                <span>
                    <p style={{fontSize: "51px", fontFamily: "Cherolina"}}>Forever begine today</p>
                    <p style={{fontSize: "20px", fontFamily: "Ethereal"}}>{groomNameEn} . {brideNameEn}</p>
                </span>
            </div>
            <img className='main_fall' src={weddingData.mainImg} alt='Main Image' style={{width: photoWidth?photoWidth:"319px"}}/>

            <p style={{fontSize: "21px", fontFamily: "Milchella"}}>{weddingData.year}.{weddingData.month}.{weddingData.date} {weddingData.dayOfWeek} {weddingData.time}</p>
        </div>
    );
};

export default Main_Fall;