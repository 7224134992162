import React from 'react';
import "../css/common.css"; 
import xImg from '../css/x.png';

const ModalTemplate = ({ isOpen, title, description, children, tmpCloseModal, backgroundColor = 'rgba(0, 0, 0, 0.5)' , color, fontFamilyKo}) => {
    if (!isOpen) return null; 

    return (
        <div className='modal' style={{ backgroundColor }}>
            <div className='modal_content'>
                <div style={{ width: "100%", height: "11px", display: "flex", justifyContent: "right" }}>
                    <img className='x_img' src={xImg} alt='Close' onClick={tmpCloseModal} />
                </div>
                <h3 className='spring_h3_title' style={{color: color}}>{title}</h3>
                {description && (
                    <p className='p1_score_14' style={{ paddingTop: "20px", paddingBottom: "5px", fontWeight: 500, fontFamily: fontFamilyKo}}>
                        {description}
                    </p>
                )}
                {children}
            </div>
        </div>
    );
};

export default ModalTemplate;
