import React, {useState, useEffect} from 'react';
import MotionWrapper from '../MotionWrapper';

function getOrdinalSuffix(num) {
    const lastDigit = num % 10; // 마지막 숫자
    const secondLastDigit = Math.floor(num / 10) % 10; // 두 번째 마지막 숫자 (십의 자리)

    // 예외 처리: 11, 12, 13은 "th"로 처리
    if (secondLastDigit === 1) {
        return "th";
    }

    switch (lastDigit) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

const Info_Fall = ({weddingData, isFormatWeddingTime}) => {
    const [dayOfWeek, setDayOfWeek] = useState("");
    const [dayOfWeekEn, setDayOfWeekEn] = useState("");
    const [monthEn, setMonthEn] = useState("");
    const [ordinalNum, setOrdinalNum]= useState("");
    useEffect(() => {
        if(weddingData.dayOfWeek) {
            if(weddingData.dayOfWeek === "SAT"){
                setDayOfWeek("토");
                setDayOfWeekEn("Saturday");
            } else if(weddingData.dayOfWeek === "SUN"){
                setDayOfWeek("일");
                setDayOfWeekEn("Sunday")
            }
        }

        if(weddingData.month) {
            if(weddingData.month === "02"){
                setMonthEn("February");
            } else if(weddingData.month === "03"){
                setMonthEn("March");
            } else if(weddingData.month === "04"){
                setMonthEn("April");
            } else if(weddingData.month === "05"){
                setMonthEn("May");
            }
        }

        if (weddingData.date) {
            const num = parseInt(weddingData.date, 10); // "01"을 1로 변환
            const ordinalSuffix = getOrdinalSuffix(num); // 서수 접미사 구하기
            setOrdinalNum(`${num}${ordinalSuffix}`); // "1st", "2nd" 형태로 설정
        }
    }, [weddingData]);

    const getAmPmKo = (weddingTime) => {
        const [hour] = weddingTime.split(':').map(Number); // 시간을 추출하고 숫자로 변환
        return hour < 12 ? '오전' : '오후'; // 12시 미만은 오전, 이상은 오후
    };

    const getAmPm = (weddingTime) => {
        const [hour] = weddingTime.split(':').map(Number); // 시간을 추출하고 숫자로 변환
        return hour < 12 ? 'AM' : 'PM'; // 12시 미만은 AM, 이상은 PM
    };

    const formatTime = (weddingTime, isFormat) => {
        const [hour, minute] = weddingTime.split(":").map(Number); // 시간과 분 추출
        const formattedHour = isFormat ? (hour > 12 ? hour - 12 : hour) : hour; // 12시간제로 변환
        return `${formattedHour}:${minute < 10 ? `0${minute}` : minute}`; // "시간:분" 형식으로 반환
    };

    return (
        <div style={{fontFamily: "JejuMyeongjo", display: "flex", flexDirection: "column", textAlign:"center", alignItems: "center"}}>
            <MotionWrapper> 
                <div style={{display: "flex", width : "220px", justifyContent:"space-between", fontSize: "17px", margin: "45px 0"}}>
                    <span style={{display: "flex", width:"88px", justifyContent: 'space-around'}}>
                        <p>신랑</p> <p>{weddingData.groomName}</p>
                    </span>
                    <span style={{display: "flex", width:"88px", justifyContent: 'space-around'}}>
                        <p>신부</p> <p>{weddingData.brideName}</p>
                    </span>
                </div>
            </MotionWrapper>

            <MotionWrapper> 
                <span style={{fontSize: "16px", lineHeight: "1.3em"}}>
                    <p>{weddingData.year}년 {weddingData.month}월 {weddingData.date}일 {dayOfWeek}요일 {getAmPmKo(weddingData.time)} {formatTime(weddingData.time, isFormatWeddingTime)}</p>
                    <p style={{color: "#898989"}}>{dayOfWeekEn}, {monthEn} {ordinalNum}, {weddingData.year} {getAmPm(weddingData.time)} {formatTime(weddingData.time, isFormatWeddingTime)}</p>
                </span>
            </MotionWrapper>

            <MotionWrapper> 
                <p style={{fontSize: "16px", marginTop: "25px", marginBottom : "96px"}}>{weddingData.place}</p>
            </MotionWrapper>
        </div>
    );
};

export default Info_Fall;