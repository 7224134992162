import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import exportImg from '../css/kakao.png';
import cakeImg from '../css/guestBook/guest_2.png';
import cherryImgRed from '../css/guestBook/cherry_red.png';
import cherryImgBlack from '../css/guestBook/cherry_black.png';
import '../css/guestBookCake.css';
import moment from 'moment-timezone';
import ModalTemplate from '../component/ModalTemplate'; 
import xImg from '../css/x.png'
import GuestMessageCreateModal from '../component/Modal/GuestMessageCreateModal';
import GuestMessageValidateModal from '../component/Modal/GuestMessageValidateModal';
import axios from 'axios'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Loading from '../component/Loading';
import GuestBookList from '../component/GuestBook/GuestBookList';


const GuestBookCake = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { mainPage } = useParams(); 

    const [loading, setLoading] = useState(true);

    const [guestMessages, setGuestMessages] = useState([]);
    const [totalMsgCnt, setTotalMsgCnt] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0); 
    const size = 10; 
    const [currentLastPage, setCurrentLastPage] = useState(0);
    const swiperRef = useRef(null); // Swiper 인스턴스 참조

    const [leftTime, setLeftTime] = useState(""); 
    const [isWeddingOpen, setWeddingOpen] = useState(false); 
    const [isCherryModalOpen, setCherryModalOpen] = useState(false); 
    const [isModalOpen, setModalOpen] = useState(false); 
    const [isGuestMessageModalOpen, setGuestMessageModalOpen] = useState(false);
    const [isGuestMessageValidateModalOpen, setGuestMessageValidateModalOpen] = useState(false);
    const [clickedCherryIndex, setClickedCherryIndex] = useState(null);
    const [cherryImg, setCherryImg] = useState("");

    const [isListModalOpen, setIsListModalOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState("");
    const [selectedName, setSelectedName] = useState("");

    const [targetDate, setTargetDate] = useState(null);

    const handleGoBack = () => {
        navigate(`/${mainPage}`);
    };

    const handleOpenGuestMessageModal = () => {
        setGuestMessageModalOpen(true);
    };


    const handleOpenValidateGuestMessageModal = () => {
        setGuestMessageValidateModalOpen(true);
    };

    const calculateTimeLeft = useCallback(() => {
        if (!targetDate) return ""; // targetDate가 초기화되지 않았으면 빈 문자열 반환

        const now = moment.tz("Asia/Seoul");
        const difference = targetDate.diff(now);

        let timeLeft = "";
        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            if (days > 0) {
                timeLeft = `${days}일`;
            } else if (hours > 0) {
                timeLeft = `${hours}시간`;
            } else if (minutes > 0) {
                timeLeft = `${minutes}분`;
            } else {
                timeLeft = `${seconds}초`;
            }
        } else {
            timeLeft = "결혼식이 이미 진행되었습니다.";
            setWeddingOpen(true);
        }
        return timeLeft;
    }, [targetDate]);

    useEffect(() => {
        if (targetDate) {
            const intervalId = setInterval(() => {
                setLeftTime(calculateTimeLeft());
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [targetDate]);

    const handleCherryClick = (index, id) => {
        if (isWeddingOpen) {
            setModalOpen(true);
            fetchGuestMessage(id); // 선택한 체리의 ID를 전달하여 API 호출
            setCherryModalOpen(false);
        } else {
            setCherryModalOpen(true);
            setModalOpen(false);
        }
        setClickedCherryIndex(index);
    };
    
    const closeCherryModal = () => {
        setCherryModalOpen(false);
    };

    const tmpCloseModal = () => {
        setModalOpen(false); 
    };

    const tmpCloseCreateMessageModal = () => {
        setGuestMessageModalOpen(false); 
    };

    const tmpCloseValidateMessageModal = () => {
        setGuestMessageValidateModalOpen(false); 
    };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        pointColor:'',
        btnBgColor:'',
        btnFontColor:'',
        isGuestBookOpen:''
    });

    useEffect(() => {
        fetchInvitationInfoData();
        fetchData(); 
    }, []);

    useEffect(() => {
        if (weddingData && weddingData.isGuestBookOpen === "N") { // isGuestBookOpen == true > 날짜 이전으로 설정
            const calculatedTargetDate = moment.tz(weddingData.weddingDate, "YYYY-MM-DD", "Asia/Seoul");
            setTargetDate(calculatedTargetDate);
        } else if(weddingData && weddingData.isGuestBookOpen === "Y") {
            setTargetDate('');
            setWeddingOpen(true);
            setCherryModalOpen(false);
        }
    }, [weddingData]);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            console.log(response.data, ": responses")
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');
                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    const concept = response.data.data.concept;
                    const matchingStyle = conceptStyles.find(style => style.concept === concept);

                    setConceptStyle(matchingStyle); 
                    setLoading(false);
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/guest_book/${mainPage}/all?page=${currentPage}&size=${size}`);
            // console.log("response", response)
            setGuestMessages(response.data.data.content);
            setTotalMsgCnt(response.data.data.totalElements);
            setTotalPage(response.data.data.totalPages);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [conceptStyle, setConceptStyle] = useState(null);
    const conceptStyles = [
        {
            concept: "SPRING",
            fontFamilyKo: "SCDream3",
            fontFamilyEn: "Edensor"
        },
        {
            concept: "SUMMER",
            fontFamilyKo: "SCDream3",
            fontFamilyEn: "Edensor"
        },
        {
            concept: "FALL",
            fontFamilyKo: "JejuMyeongjo",
            fontFamilyEn: "Milchella"
        },         
        {
            concept: "WINTER",
            fontFamilyKo: "JejuMyeongjo",
            fontFamilyEn: "Milchella"
        }
    ];

    const handleSubmitMessage = () => {
        fetchData();
        if (totalPage !== currentLastPage) {
            setCurrentLastPage(totalPage); // 상태 업데이트
            if (swiperRef.current) {
                swiperRef.current.slideTo(totalPage - 1); // Swiper의 마지막 페이지로 이동
            }
        }
        alert('방명록 작성이 완료되었습니다🤍');
    };

    useEffect(() => {
        fetchData(currentPage); 
    }, [currentPage]); 
    
    useEffect(() => {
        if (conceptStyle && conceptStyle.concept) {
            if (conceptStyle.concept === "SUMMER") {
                setCherryImg(cherryImgRed);
            } else if (conceptStyle.concept === "SPRING") {
                setCherryImg(cherryImgRed);
            } else if (conceptStyle.concept === "FALL") {
                setCherryImg(cherryImgBlack);
            } else if (conceptStyle.concept === "WINTER") {
                setCherryImg(cherryImgBlack);
            }
        }
    }, [conceptStyle]);
    

    const handleSlideChange = (swiper) => {
        const newIndex = swiper.activeIndex; 
        setCurrentPage(newIndex); 
        setClickedCherryIndex(null);
    };

    const fetchGuestMessage = async (id) => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/guest_book/${id}`);
            if (response.status === 200) {
                setSelectedMessage(response.data.data.message); // 첫 번째 <p> 태그에 들어갈 내용
                setSelectedName(response.data.data.name); // 두 번째 <p> 태그에 들어갈 내용
            }
        } catch (error) {
            console.error("Error fetching guest message:", error);
        }
    };

    const getFirstNameKo = (fullName) => {
        const nameParts = fullName.split('');
        return nameParts.slice(1).join(''); 
    };
    
    // const openTitle = `${getFirstNameKo(weddingData.groomName)} ♥ ${getFirstNameKo(weddingData.brideName)} 의 웨딩 케이크를 \n 방명록 메세지로 가득 채워주세요`
    const shareGuestBook = () => {
        const imageUrl = 
            conceptStyle.concept === "SUMMER" || conceptStyle.concept === "SPRING" 
                ? "https://moodsean.s3.ap-northeast-2.amazonaws.com/RedCake.png"
                : conceptStyle.concept === "FALL" || conceptStyle.concept === "WINTER" 
                ? "https://moodsean.s3.ap-northeast-2.amazonaws.com/BlackCake.png"
                : "https://moodsean.s3.ap-northeast-2.amazonaws.com/RedCake.png";
    
        window.Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
                title: `${getFirstNameKo(weddingData.groomName)} ♥ ${getFirstNameKo(weddingData.brideName)} 의 웨딩 케이크를 \n방명록 메세지로 가득 채워주세요`,
                description: `${weddingData.year}년 ${weddingData.month}월 ${weddingData.date}일 ${weddingData.time}`,
                imageUrl: imageUrl,
                link: {
                    mobileWebUrl: `https://moodsean.com/${mainPage}/guest`,
                    webUrl: `https://moodsean.com/${mainPage}/guest`,
                },
            },
            buttons: [
                {
                    title: '방명록 작성하러 가기',
                    link: {
                        mobileWebUrl: `https://moodsean.com/${mainPage}/guest`,
                        webUrl: `https://moodsean.com/${mainPage}/guest`,
                    },
                }
            ],
        });
    };
    
    const openListModal = () => {
        setModalOpen(false); 
        setIsListModalOpen(true); 
    };

    const closeListModal = () => {
        setIsListModalOpen(false); 
    };

    if (!weddingData || !conceptStyle) {
        return <Loading color={"#000000"} loading={loading} />;
    }
    
    const message = `${getFirstNameKo(weddingData.groomName)} ♥ ${getFirstNameKo(weddingData.brideName)}에게 \n ${totalMsgCnt}개의 방명록이 전달됐어요`;

    return (
        <div style={{display: "flex", justifyContent:"center", height: "100vh"}} > 
            {loading ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
            <> 
            <div className="guestBook_container">
                <img className='back_button' src={xImg} alt='Close' onClick={handleGoBack} />
                <p className='guestBook_info' style={{fontFamily: conceptStyle.fontFamilyKo, lineHeight: "1.2em"}}> 
                    {message}
                </p>
                <div className="cake_container">
                <Swiper 
                    cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true} 
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]} 
                    className='mySwiper'
                    speed={0} 
                    onSlideChange={handleSlideChange}
                    initialSlide={Math.max(totalPage - 1, 0)} // 마지막 슬라이드로 설정
                >
                    {totalPage > 0 ? (
                        Array.from({ length: totalPage }, (_, index) => (
                            <SwiperSlide key={index}>
                                <img className='guest_cake_img' src={cakeImg} alt='Cake Image' />
                            </SwiperSlide>
                        ))
                    ) : (
                        <SwiperSlide>
                            <img className='guest_cake_img' src={cakeImg} alt='Default Cake Image' />
                        </SwiperSlide>
                    )}
                </Swiper>
                    {guestMessages.map((item, index) => (
                        <img
                            key={index}
                            id={`cherry_${index}`}
                            className="cherry_img"
                            src={cherryImg}
                            alt="Cherry Image"
                            onClick={() => handleCherryClick(index, item.id)} // item의 id를 전달
                            style={{
                                opacity: clickedCherryIndex === index ? 0.5 : 1
                            }}
                        />
                    ))}
                </div>
                <div className="guestBook_export" style={{display: "flex", width: "340px", justifyContent:"space-around", marginBottom: "29px", marginTop: "10px"}}>
                    <button style={{width : "155px", backgroundColor: weddingData.btnBgColor, color: weddingData.btnFontColor, fontFamily: conceptStyle.fontFamilyKo}} className='spring_btn' onClick={handleOpenGuestMessageModal}>작성하기</button>
                    <button style={{width : "155px", backgroundColor: weddingData.btnBgColor, color: weddingData.btnFontColor, fontFamily: conceptStyle.fontFamilyKo}} className='spring_btn' onClick={handleOpenValidateGuestMessageModal}>수정하기</button>
                </div>

                {isGuestMessageModalOpen && (
                    <GuestMessageCreateModal isModalOpen={isGuestMessageModalOpen} tmpCloseModal={tmpCloseCreateMessageModal} onSubmit={handleSubmitMessage} invitationInfoId={weddingData.id} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                )}

                {isGuestMessageValidateModalOpen && (
                    <GuestMessageValidateModal isModalOpen={isGuestMessageValidateModalOpen} setGuestMessageValidateModalOpen={setGuestMessageValidateModalOpen} tmpCloseModal={tmpCloseValidateMessageModal} invitationInfoId={weddingData.id} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                )}
                <span style={{display: "flex", alignItems: "center", width: "133px", justifyContent: "center"}} onClick={shareGuestBook}>
                    <img src={exportImg} alt='export Image' style={{width: "17px", height: "17px", marginRight: "4px"}}/>
                    <p style={{fontSize : "13px", fontFamily: conceptStyle.fontFamilyKo}}>케이크 공유하기</p>
                </span>

                {/* 체리 클릭 시 모달 */}
                {isModalOpen && (
                    <ModalTemplate
                        isOpen={isModalOpen}
                        tmpCloseModal={tmpCloseModal}
                        backgroundColor="rgba(255, 255, 255, 0.5)"
                        fontFamilyKo={conceptStyle.fontFamilyKo}
                    >
                        <img style={{width: "53px"}} src={cherryImg} alt="Cherry Image" />
                        <p style={{fontSize: "12px", padding: "38px 0", fontFamily: conceptStyle.fontFamilyKo}}>{selectedMessage}</p> {/* response.data.message */}
                        <p style={{fontSize: "15px", paddingBottom: "20px", fontFamily: conceptStyle.fontFamilyKo}}>FROM. {selectedName}</p> {/* response.data.name */}
                        <button 
                                style={{width: "160px", margin: "20px 1px", fontFamily: conceptStyle.fontFamilyKo, color: weddingData.btnFontColor, backgroundColor: weddingData.btnBgColor}} 
                                onClick={openListModal}
                                className="spring_btn">리스트로 보기</button>
                    </ModalTemplate>
                )}

                {/* 결혼식 진행 전 모달 */}
                {isCherryModalOpen&& (
                    <ModalTemplate
                    isOpen={isCherryModalOpen}
                    tmpCloseModal={closeCherryModal}
                    backgroundColor="rgba(255, 255, 255, 0.5)"
                    fontFamilyKo={conceptStyle.fontFamilyKo}
                    >
                    <p style={{fontSize: "10px", paddingBottom: "3px"}}>아직 열람할 수 없습니다.</p>
                    <p style={{fontSize: "10px"}}>방명록 열람까지 <span style={{color : conceptStyle.pointColor}}>{leftTime}</span> 남았습니다.</p>
                </ModalTemplate>
                )} 

                {/* 리스트 보기 모달 */}
                {isListModalOpen && (
                <ModalTemplate
                    isOpen={isListModalOpen}
                    tmpCloseModal={closeListModal}
                    backgroundColor="rgba(0, 0, 0, 0.5)"
                    fontFamilyKo={conceptStyle.fontFamilyKo}
                >
                    <GuestBookList invitationInfoId={weddingData.invitationInfoId} pointColor={weddingData.pointColor}/>

                    <button style={{width : "140px", backgroundColor: weddingData.btnBgColor, color: weddingData.btnFontColor, fontFamily: conceptStyle.fontFamilyKo}}
                        onClick={closeListModal}
                        className="spring_btn"
                    >
                        닫기
                    </button>
                </ModalTemplate>
            )}
            </div>
            </>
            )}
        </div>
    );
};

export default GuestBookCake;
