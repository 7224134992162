import React from 'react';

const Footer = () => {
    return (
        <div className='basic_column_container'
            style={{
                paddingTop: "100px"
        }}>

            <h3 style={{
                fontFamily: "QESTERO",
                fontSize: "13px"
            }}>
                MOODSEAN
            </h3>

            <p style={{
                fontSize: "7px",
                fontFamily: "Noto Sans",
                color: "#9E9E9E",
                fontWeight: "400",
                marginTop: "10px",
                marginBottom: "20px"
            }}>
                COPYRIGHT MOODSEAN. All rights reserved.</p>
        </div>
    );
};

export default Footer;