import React from 'react';
import lineImg from '../../css/vertical_line.png';
import MotionWrapper from '../MotionWrapper';

const Save_Date_Fall = ({weddingData, color}) => {
    return (
        <div style={{color: color, display:"flex", flexDirection: "column", alignItems:"center", height: "324px", justifyContent: "space-between", marginTop: "150px"}}>
            <MotionWrapper> 
                <div style={{display: "flex", alignItems: "center", width: "261px", justifyContent: "space-between"}}>
                    <p style={{fontFamily: "QESTERO", fontSize: "45px"}}>SAVE</p>
                    <p style={{fontFamily: "Autography", fontSize: "35px"}}>the</p>
                    <p style={{fontFamily: "QESTERO", fontSize: "45px"}}>DATE</p>
                </div>
            </MotionWrapper>

            <MotionWrapper> 
                <img style={{height:"45px"}} src={lineImg} alt='Main Image'></img>
            </MotionWrapper>

            <MotionWrapper> 
                <div style={{fontSize: "30px", fontFamily:"Milchella", height: "114px", display:"flex", flexDirection: "column", justifyContent:"space-between", textAlign: "center"}}>
                    <p>{weddingData.year.toString().slice(-2)}</p>
                    <p>{weddingData.month}</p>
                    <p>{weddingData.date}</p>
                </div>
            </MotionWrapper>

            <MotionWrapper> 
                <img style={{height:"45px"}} src={lineImg} alt='Main Image'></img>
            </MotionWrapper>
            
        </div>
    );
};

export default Save_Date_Fall;