import React, {useState, useEffect, useRef} from 'react';

const Admin = () => {
    return (
        <div style={{width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
           <div style={{width: "200px", margin: "50px auto"}}>
                <span >
                    <p>아이디</p>
                    <input
                        type='text'
                        name='name'
                        placeholder='아이디'
                        // value={formData.name}
                        // onChange={handleInputChange}
                    />
                </span>


           <button className='spring_btn' style={{width: "100px", margin: "20px auto"}}>
                로그인
           </button>
           </div>
        </div>
    );
};

export default Admin;<h1>Admin</h1>