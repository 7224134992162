import React, { useState, useEffect } from 'react';
import ModalTemplate from '../ModalTemplate';
import axios from 'axios'; 

const GuestMessageUpdateModal = ({ 
    setGuestMessageValidateModalOpen, 
    isModalOpen, 
    setIsModalOpen, 
    tmpCloseModal, 
    conceptStyle, 
    btnBgColor, 
    btnFontColor, 
    titleColor, 
    guestBookInfo 
}) => {
    const API_URL = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({ name: '', message: '' });
    const [isFormComplete, setIsFormComplete] = useState(false);

    // 초기값 설정
    useEffect(() => {
        if (guestBookInfo) {
            setFormData({
                name: guestBookInfo.name || '', // 작성자 초기값
                message: guestBookInfo.message || '', // 메시지 초기값
            });
        }
    }, [guestBookInfo]);

    // 모든 필드가 채워졌는지 확인
    useEffect(() => {
        const isAllFieldsFilled = Object.values(formData).every((value) => value !== '');
        setIsFormComplete(isAllFieldsFilled);
    }, [formData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const submitMessage = async () => {
        const requestData = {
            id: guestBookInfo.id,
            name: formData.name,
            message: formData.message,
        };

        try {
            const response = await axios.put(`${API_URL}/_api/v1/guest_book`, requestData, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.status === 200) {
                setIsModalOpen(false);
                setGuestMessageValidateModalOpen(false);
                alert('방명록 수정이 완료되었습니다🤍')
            }
        } catch (error) {
            console.error('에러 발생:', error.response?.data || error.message);
        }
    };

    return (
        <div>
            <ModalTemplate
                isOpen={isModalOpen}
                title="MESSAGE"
                description="방명록을 수정해 주세요"
                tmpCloseModal={tmpCloseModal}
                fontFamilyKo={conceptStyle.fontFamilyKo}
                color={titleColor}
            >
                <div className='form_group'>
                    <input
                        type='text'
                        name='name'
                        placeholder='작성자'
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <textarea
                        name='message'
                        placeholder='방명록을 작성해 주세요'
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                </div>
                <button
                    className='spring_btn'
                    onClick={submitMessage}
                    disabled={!isFormComplete}
                    style={{
                        width: '219px',
                        marginTop: '10px',
                        fontFamily: conceptStyle.fontFamilyKo,
                        backgroundColor: btnBgColor,
                        color: btnFontColor,
                    }}
                >
                    수정하기
                </button>
            </ModalTemplate>
        </div>
    );
};

export default GuestMessageUpdateModal;
