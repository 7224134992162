import React from 'react';
import { motion } from 'framer-motion';

const MotionWrapper = ({ children, initialY = 70, opacity = 0.3 }) => (
    <motion.div
        initial={{ opacity: opacity, y: initialY }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{
            ease: "easeInOut",
            duration: 1.3, // 애니메이션 지속 시간
        }}
    >
        {children}
    </motion.div>
);

export default MotionWrapper;