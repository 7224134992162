import React, { useState, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox"; // 새 라이브러리 import
import "yet-another-react-lightbox/styles.css"; // 스타일 추가
import axios from "axios";
import moreBtn from "../css/moreBtn.png"
import foldBtn from "../css/foldBtn.png"

import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";

import "../css/gallery_cards.css";

const Gallery_Cards = ({ weddingData, galleryImgTitle, initCount, photoWidth, photoHeight, gap }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [galleryList, setGalleryList] = useState([]);
  const [visibleCount, setVisibleCount] = useState(initCount);
  const [initialVisibleCount, setInitialVisibleCount]  = useState(initCount);

  const fetchGallery = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/_api/v1/invitation_info/gallery/${weddingData.id}`
      );
      // console.log("gallery :", response )
      if (response.status === 200) {
        setGalleryList(response.data.data);
      }
    } catch (error) {
      console.error(
        "API 요청 실패:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    // weddingData가 있고, galleryList가 비어있을 때만 API 호출
    if (weddingData && galleryList.length === 0) {
      fetchGallery();
    }
  }, [weddingData]); 

  const handleImageClick = (index, event) => {
    event.preventDefault(); // 기본 동작 차단
    setIndex(index);
    setOpen(true);
  };

  const slides = galleryList.map((src, idx) => {
    const slide = { src };
    if (galleryImgTitle && galleryImgTitle[idx]) {
      slide.title = galleryImgTitle[idx];
    }
    return slide;
  });

  const loadMoreImages = () => {
    setVisibleCount((prevCount) => {
      return prevCount + initCount;
    });
  };

  const resetImages = () => {
    setVisibleCount(initCount); 
    setInitialVisibleCount(initCount);
  };

  return (
    <div className="gallery_card_container" style={{gap: gap}}>
      {galleryList.slice(0, visibleCount).map((image, idx) => (
        <img
          key={idx}
          className="card_photo"
          style={{width: photoWidth, height: photoHeight, maxWidth: "329px"}}
          src={image}
          alt={`photo${idx + 1}`}
           onClick={(event) => handleImageClick(idx, event)}
        />
      ))}

      <div style={{ textAlign: "center", marginTop: "20px", width: "300px", height: "50px" }}>
      {visibleCount < galleryList.length && (
        <span
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={loadMoreImages}
        >
          {/* <p style={{ fontSize: "15px", marginRight: "5px" }}>더보기</p> */}
          <img style={{ width: "15px", height: "10px", cursor: "pointer" }} src={moreBtn} />
        </span>
    )}

    {visibleCount !== initialVisibleCount && visibleCount >= galleryList.length && (
      <span
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={resetImages}
      >
        <img style={{ width: "15px", height: "10px", cursor: "pointer"  }} src={foldBtn} />
      </span>
    )}

      </div>

      {open && (
        <Lightbox
          open={open}
          index={index}
          close={() => setOpen(false)}
          slides={slides} // 동적으로 생성한 slides 전달
          onIndexChange={setIndex} // 스와이프할 때 인덱스 변경
          discourageDownloads={true}  // 이미지 저장 비활성화
          clickOutsideToClose={false}
          enableZoom={false}
          styles={{
            root: { "--yarl__color_backdrop": "rgba(255, 255, 255, .9)" },
          }}
          plugins={[Captions]}
        />
      )}
    </div>
  );
};

export default Gallery_Cards;
