import React from 'react';
import lineImg from '../../css/line_hori_gray.png';

const Location_Desc = ({ forCar, forParking, forBus, forSubway, forShuttleBus, forTrain, conceptStyle, titleFontSize, fontSize }) => {
    return (
        <div>
            <div style={{ color: "#313131", display: "flex", flexDirection: "column", justifyContent: "center", margin: "50px 0", alignItems: "center", lineHeight: "1.2rem", fontFamily: conceptStyle.fontFamilyKo}}>

                {forShuttleBus && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600"}}>셔틀버스 이용안내</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0" }}>{forShuttleBus}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", marginBottom: "10px" }} />
                    </>
                )}

                {forCar && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600" }}>자가용 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0" }}>{forCar}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", marginBottom: "10px" }} />
                    </>
                )}

                {forParking && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600" }}>주차장 안내</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0" }}>{forParking}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", marginBottom: "10px" }} />
                    </>
                )}

                {forBus && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600" }}>버스 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0" }}>{forBus}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", marginBottom: "10px" }} />
                    </>
                )}

                {forSubway && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: "14px", margin: "10px 0" , fontWeight: "600"}}>지하철 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0" }}>{forSubway}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", marginBottom: "10px" }} />
                    </>
                )}


                {forTrain && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: "14px", margin: "10px 0" , fontWeight: "600"}}>철도 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0" }}>{forTrain}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", marginBottom: "10px" }} />
                    </>
                )}
            </div>
        </div>
    );
};

export default Location_Desc;
