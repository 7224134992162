import React from 'react';
import "../css/kakao_map.css"
import { CustomOverlayMap, Map, MapMarker } from "react-kakao-maps-sdk";

const KakaoMap = ({lng,lat, level}) => {

    return (
        <div className='kakao_map_wrap'>
        <Map // 지도를 표시할 Container
            center={{   // 지도의 중심좌표
                lat: lat,
                lng: lng
            }}
            style={{
                // 지도의 크기
                width: "80%",
                height: "254px",
                margin: "0 auto",
                maxWidth: "500px"
                // boxShadow: "0 0 20px",
            }}
            level={level ? level : 4} // 지도의 확대 레벨
        >
            <MapMarker // 마커를 생성합니다
                position={{               
                    lat: lat,
                    lng: lng
                }}
            />
            <CustomOverlayMap
                position={{ 
                    lat: lat,
                    lng: lng
                }}
                yAnchor={1}
                >
            </CustomOverlayMap>
        </Map>
    </div>
    );
};

export default KakaoMap;