import React, { useState, useRef, useEffect } from "react";
import musicStopImg from "../css/music_stop.png";
import musicPlayImg from "../css/music_start.png"; 
import springMusicFile from "../asset/music/spring.mp3";
import summerMusicFile from "../asset/music/summer.mp3";
import winterMusicFile from "../asset/music/winter.mp3";

const Music = ({ concept, inital, musicTitle }) => {
    const [isPlaying, setIsPlaying] = useState(inital ? inital : false);
    const audioRef = useRef(null);

    useEffect(() => {
        // Audio 객체 초기화
        const audio = new Audio();
        if(musicTitle != null) {
            audio.src = musicTitle;
        } else {
            if (concept === "SPRING") {
                audio.src = springMusicFile;
            } else if (concept === "SUMMER" || concept === "FALL"){
                audio.src = summerMusicFile;
            } else if (concept === "WINTER") {
                audio.src = winterMusicFile;
            }
        }

        audio.loop = true;
        audioRef.current = audio;

    }, [concept]); 

    const handleMusicToggle = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause(); // 재생 중이면 멈춤
        } else {
            audio.play(); // 멈춤 상태라면 재생
        }
        setIsPlaying(!isPlaying); // 상태 업데이트
    };

    return (
        <div>
            <img 
                src={isPlaying ? musicPlayImg : musicStopImg} 
                style={{
                    width: "29px",
                    position: "fixed",
                    top: "1%",
                    right: "3%",
                    cursor: "pointer",
                    zIndex: "1500"
                }}
                onClick={handleMusicToggle} 
                alt="Music Control"
            />
        </div>
    );
};

export default Music;
