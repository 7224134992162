import React, { useState, useEffect } from 'react';
import ModalTemplate from '../ModalTemplate';
import GuestMessageUpdateModal from './GuestMessageUpdateModal';
import axios from 'axios'; 

const GuestMessageValidateModal = ({setGuestMessageValidateModalOpen, invitationInfoId, isModalOpen, tmpCloseModal, conceptStyle, btnBgColor, btnFontColor, titleColor }) => {
    const API_URL = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({ name: '', password: '' });
    const [isFormComplete, setIsFormComplete] = useState(false); 
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);

    const [guestBookInfo, setGuestBookInfo] = useState({
        id: '',
        name: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const goToUpdateModal = async () => {
        const requestData = {
            invitationId: invitationInfoId,
            name: formData.name,
            password: formData.password,
        };

        try {
            const response = await axios.post(`${API_URL}/_api/v1/guest_book/validate`, requestData, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) { 
                setGuestBookInfo({
                    id: response.data.data.id,
                    name: response.data.data.name,
                    message: response.data.data.message,
                });
                setUpdateModalOpen(true);
            }
        } catch (error) {
            alert("유효한 정보가 아닙니다 다시 입력해주세요 😭")
            console.error('에러 발생:', error.response?.data || error.message);
        }
    };

    const tmpCloseUpdateModal = () => {
        setUpdateModalOpen(false);
    };

    useEffect(() => {
        const isAllFieldsFilled = Object.values(formData).every(value => value !== '');
        setIsFormComplete(isAllFieldsFilled);
    }, [formData]);

    return (
        <div>
            <ModalTemplate
                isOpen={isModalOpen}
                title="MESSAGE"
                description="정보를 입력해주세요."
                tmpCloseModal={tmpCloseModal}
                fontFamilyKo={conceptStyle.fontFamilyKo}
                color={titleColor}
            >
                <div className='form_group'>
                    <input
                        type='text'
                        name='name' 
                        placeholder='작성자'
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <input
                        type='password'
                        name='password'
                        placeholder='휴대폰번호 뒷자리'
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                </div>
                <button 
                    className='spring_btn' 
                    onClick={goToUpdateModal} 
                    disabled={!isFormComplete} 
                    style={{width : "219px", marginTop:"10px", backgroundColor: btnBgColor, color: btnFontColor, fontFamily: conceptStyle.fontFamilyKo}}
                >
                    다음
                </button>
            </ModalTemplate>

            {isUpdateModalOpen && guestBookInfo.id && (
                <GuestMessageUpdateModal 
                    guestBookInfo={guestBookInfo} 
                    isModalOpen={isUpdateModalOpen} 
                    setIsModalOpen={setUpdateModalOpen} 
                    tmpCloseModal={tmpCloseUpdateModal} 
                    conceptStyle={conceptStyle} 
                    btnBgColor={btnBgColor} 
                    btnFontColor={btnFontColor} 
                    titleColor={titleColor} 
                    setGuestMessageValidateModalOpen={setGuestMessageValidateModalOpen}
                    guestBookInfo={guestBookInfo}
                />
            )}
        </div>
    );
};

export default GuestMessageValidateModal;
