import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import Interview_Spring from '../component/Spring/Interview_Spring';
import babyStepMusicFile from "../asset/music/babyStep.mp3";

const LeeGaHyang = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "전준규♥박호정 결혼합니다🤵🏻👰🏻";
    const openDesc = "04월 05일 12시10분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/26/thumb.jpeg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "전준규",
            bank: "신한은행",
            accountNumber : "110-369-722236",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej77CwxNZ"
        },
        {
            role: "신랑 부",
            name: "전형남",
            bank: "우리은행",
            accountNumber : "109-07-042813",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FJ0zz6vjh"
        },
        {
            role: "신랑 모",
            name: "이재희",
            bank: "우리은행",
            accountNumber : "1002-743-186503",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FU3nkhCRL"
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "박호정",
            bank: "국민은행",
            accountNumber : "920301-01-622210",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej8vlkAcR"
        },
        {
            role: "신부 부",
            name: "박준일",
            bank: "국민은행",
            accountNumber : "240-21-0583-473",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej9GAYYwo"
        },
        {
            role: "신부 모",
            name: "윤성숙",
            bank: "농협은행",
            accountNumber : "325- 1102-6716-23",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej89jmDWh"
        }
    ]


    const interviewContent = [
        {
            question : "서로의 첫인상은?",
            groomAnswer: "귀엽다",
            brideAnswer: "반듯한 청년"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "기대된다",
            brideAnswer: "신나게!!"
        },
        {
            question : "서로의 장점 한가지!",
            groomAnswer: "스마트",
            brideAnswer: "거짓말 못함"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "샤마",
            brideAnswer: "행복하게!!"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "샤마",
            brideAnswer: "샤마(레츠고)"
        }
    ]

    const msg1 = "저희에게 새로운 여정이 기다리고 있어요";
    const msg2 = "저희의 평생에 여정에 귀한 걸음내시어 \n축복해주시면 감사드리겠습니다";

    const lng = 126.83874811582;
    const lat = 37.1901174561491 ;

    const placeTelNum = "031-268-1000";

    const forBus = "[수원종합터미널에서 오시는 길]\n수원 종합터미널 → 롤링힐스 \n(25.2km)택시비 : 약 30,000원)\n\n[동서울 터미널에서 오시는 길]\n동서울 터미널(강변역)에서 지하철 이용, 사당역 하차 후 \n 1008번 버스 이용 \n(1008번 버스 약 50분 소요 / 지하철 30분 소요)\n\n[강남터미널에서 오시는 길]\n강남터미널(고속버스터미널역)에서 지하철 이용, \n사당역 하차 후 1008번 버스이용 \n(1008번 버스 약 50분 소요 / 지하철 17분 소요)\n화성 → 사당 1008번 버스\n탑승장소 사당역하차장소 롤링힐스 정류장\n사당역 10번 출구하차 후 호텔까지 도보로 이동(약 4분)";
    const forTrain = "[광명역에서 오시는 길]\n광명역 → 롤링힐스 \n(약35km, 약 40분 소요, 택시비 : 약 40,000원)\n광명역 → 사당역 8507번 셔틀버스 이용 \n(약 40분 소요), \n4번 출구에서 하차 후 10번 출구에서 1008번 버스로 환승\n\n[수원역에서 오시는 길]\n(버스) 수원역 → 남양 사거리 (400, 400-4, 999, 1004번 약1시간 소요) 남양사거리 하차 후 택시이용\n수원역 → 롤링힐스 \n(약24km, 약40분소요, 택시비: 약 30,000원)";
    
    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();

        // 스크롤 비활성화 (모바일 포함)
        const disableScroll = () => {
            document.body.style.overflow = 'hidden'; // 일반 스크롤 비활성화
            document.body.style.position = 'fixed'; // 모바일에서 터치 스크롤 비활성화
            document.body.style.width = '100%';
        };

        const enableScroll = () => {
            document.body.style.overflow = ''; // 스크롤 복구
            document.body.style.position = ''; // 모바일 복구
            document.body.style.width = '';
        };

        disableScroll();

        const timer = setTimeout(() => {
            enableScroll();
        }, 3000);

        return () => {
            clearTimeout(timer);
            enableScroll();
        };
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={babyStepMusicFile}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <Gallery_Spring color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} hotoHeight={"235px"} gap={"4px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forTrain={forTrain} forBus={forBus} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default LeeGaHyang;
