import React from 'react';
import lineImg from '../../css/line_hori.png';

const Main_Winter = ({ weddingData , groomNameEn, brideNameEn, width}) => {
        
    return (
        <div style={{display: "flex", flexDirection: "column", width: "100vw", height: "100vh", alignItems: "center", justifyContent: "space-around"}}>
            <div style={{fontSize: "15px", fontFamily: "Milchella", display: "flex", alignItems: "center", width: "330px", justifyContent: "space-between", margin: "10px 0"}}>
                {weddingData.year.slice(2)} 
                <img src={lineImg} style={{width: "179px"}}/>
                <span style={{display: "flex", width: "53px", justifyContent: "space-between"}}>
                    <p>{weddingData.month}</p>
                    <p>/</p>
                    <p>{weddingData.date}</p>
                </span>
            </div>

            <div style={{width: "300px", }}>
                    <p style={{fontSize: "40px", fontFamily: "Milchella"}}>They are now</p>
                    <p style={{fontSize: "55px", fontFamily: "Vivaldii", marginLeft: "50px"}}>husband</p>
                    <div style={{display: "flex", alignItems: "center", justifyContent:"space-between", transform: "translateY(-19%)"}}> 
                        <span style={{display: "flex", alignItems: "center", width: "141px", justifyContent: "space-between"}}>
                            <p style={{fontSize: "40px", fontFamily: "Milchella"}}>and</p>
                            <p style={{fontSize: "55px", fontFamily: "Vivaldii"}}>wife</p>
                        </span>
                        <p style={{fontSize: "15px", fontFamily: "Milchella"}}>{groomNameEn} & {brideNameEn}</p>
                    </div>
            </div>

            <img className='main_winter' src={weddingData.mainImg} alt='Main Image' style={{width: width?width:"256px",  margin: "10px 0"}}></img>


            <p style={{fontSize: "22px", fontFamily: "Milchella"}}>{weddingData.year}.{weddingData.month}.{weddingData.date} {weddingData.dayOfWeek} {weddingData.time}</p>
        </div>
    );
};

export default Main_Winter;