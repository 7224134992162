import React from "react";
import "../../css/gallery_cards.css";
import "../../css/common.css"
import line from '../../css/line.png';
import GalleryItem from "./GalleryItem";

const GalleryList = ({galleryImgTitle, color, fontFamilyEn, fontFamilyKo, fontSize, weddingData, initCount, photoWidth, photoHeight, gap}) => {
  return (
      <div className='gallery_container'>
          <h3 className='spring_h3_title' style={{paddingTop: "76px", color: color, fontFamily: fontFamilyEn}}>GALLERY</h3>
          <p className='p1_score_14' style={{fontFamily: fontFamilyKo, fontSize: fontSize?fontSize:"14px"}}> 사진을 클릭하시면 넘기며 보실 수 있습니다.</p>
          <GalleryItem galleryImgTitle={galleryImgTitle} weddingData={weddingData} initCount={initCount} photoWidth={photoWidth} photoHeight={photoHeight} gap={gap}/>
          <img className='line_img' src={line} alt='Line Image'></img>
      </div>
  );
};


export default GalleryList;
