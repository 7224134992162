import React from 'react';
import "../../css/letter.css"
import MotionWrapper from '../MotionWrapper';


const Letter = ({msg1, msg2, msg3, msg4, conceptStyle, title, titleFontSize, fontSize}) => {
        const formatMessage = (message) => {
            return message.split(/(%.*?%)/g).map((part, index) => {
            if (part.startsWith("%") && part.endsWith("%")) {
                return (
                <span key={index} style={{ fontWeight: "bold" , fontStyle: "italic", fontSize: "15px" }}>
                    {part.slice(1, -1)} {/* Remove the % symbols */}
                </span>
                );
            }
            return part; // Plain text
            });
        };
        

    return (
        <div className='letter_container' style={{fontFamily : conceptStyle.fontFamilyKo}}>
            <MotionWrapper> 
                <div className='title' style={{color : conceptStyle.pointColor, fontSize: fontSize ? titleFontSize : "17px"}}>
                    {title}
                </div>
            </MotionWrapper>
            <div className='body'>
                <MotionWrapper> 
                    <p className='body1' style={{fontFamily : conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px"}}>
                        {formatMessage(msg1)}
                    </p>
                </MotionWrapper>
                
                <MotionWrapper> 
                    <p className='body2' style={{fontFamily : conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px"}}>
                        {msg2}
                    </p>
                </MotionWrapper>

                <MotionWrapper> 
                    <p className='body2' style={{fontFamily : conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px"}}>
                        {msg3}
                    </p>
                </MotionWrapper>
                <MotionWrapper> 
                    <p className='body2' style={{fontFamily : conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px"}}>
                        {msg4}
                    </p>
                </MotionWrapper>
            </div>
        </div>
    );
};

export default Letter;